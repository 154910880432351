import React, { useContext, useRef, useState, useEffect } from "react";
import { ContextApp } from "../utils/Context";
import { LuPanelLeftClose } from "react-icons/lu";
import { RiSendPlane2Fill } from "react-icons/ri";
import { AiFillLock } from "react-icons/ai"; 
import Chat from "./Chat";
import Footer from "./Footer";
import { CircularProgress } from "@mui/material"; 

function ChatContainer() {
  const {
    setShowSlide,
    showSlide,
    chatValue,
    setChatValue,
    handleSend,
    handleKeyPress,
    isWaitingForResponse,
    isLoadingChat, 
    collections,
    selectedCollection,
    setSelectedCollection,
    isGuest,
    loadCollections,
    resetWebSocketConnection,
  } = useContext(ContextApp);

  const [dragging, setDragging] = useState(false);
  const textareaRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [textConfig, setTextConfig] = useState({});
  const [config, setConfig] = useState({});

  const handleCollectionChange = (e) => {
    const newCollection = e.target.value;
    setSelectedCollection(newCollection);
    localStorage.setItem("selectedCollection", newCollection);
    resetWebSocketConnection();
  };

  const autoResizeTextarea = (e) => {
    e.target.style.height = "inherit";
    const minHeight = 20;
    const maxHeightVh = window.innerHeight * 0.3;
    let newHeight = Math.max(e.target.scrollHeight, minHeight);
    newHeight = Math.min(newHeight, maxHeightVh);
    e.target.style.height = `${newHeight}px`;
    setChatValue(e.target.value);
    setIsExpanded(newHeight > minHeight);
  };

  const handleResizeTextareaToMin = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      const minHeight = 20;
      textareaRef.current.style.height = `${minHeight}px`;
    }
  };

  const fetchTextConfig = async () => {
    try {
      const response = await fetch("/text.json");
      const data = await response.json();
      setTextConfig(data);
    } catch (error) {
      console.error("Error loading text config:", error);
    }
  };

  const fetchConfig = async () => {
    try {
      const response = await fetch("/configuration.json");
      const data = await response.json();
      setConfig(data);
    } catch (error) {
      console.error("Error loading config:", error);
    }
  };

  useEffect(() => {
    fetchTextConfig();
    fetchConfig();
  }, []);

  useEffect(() => {
    if (chatValue === "") {
      handleResizeTextareaToMin();
    }
  }, [chatValue]);

  useEffect(() => {
    const savedCollection = localStorage.getItem("selectedCollection");
    if (savedCollection) {
      setSelectedCollection(savedCollection);
    }
  }, [setSelectedCollection]);

  return (
    <div className="relative h-screen w-full flex flex-col bg-white">
      {/* spinner for loading chat */}
      {isLoadingChat && (
        <div className="absolute inset-0 bg-gray-800 bg-opacity-50 z-50 flex items-center justify-center">
          <CircularProgress color="inherit" />
        </div>
      )}
      
      <div className={showSlide ? "h-screen w-screen bg-gray-700 flex flex-col bg-white" : "h-screen w-full bg-gray-700 flex flex-col bg-white"}>
        {showSlide && (
          <div
            className="fixed top-0 left-0 h-full w-10 z-50 cursor-pointer transition duration-300 COLOUR_LOGIN_BACKGROUND_AND_LEFT_BAR"
            title="Abrir menú"
            onClick={() => setShowSlide(false)}
          >
            <LuPanelLeftClose
              className="text-white text-4xl rotate-90"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        )}
        {!isGuest && (
          <div className={showSlide ? "w-full flex px-12 py-2" : "w-full flex px-5 py-2 " }>
            <select
              className="p-2 border rounded"
              value={selectedCollection}
              onFocus={loadCollections}
              onChange={handleCollectionChange}
              disabled={isWaitingForResponse}
            >
              <option value="">{textConfig.CHAT_COLLECION_SELECT}</option>
              
              {/* add default collection */}
              {config.DEFAULT_COLLECTION && (
                <option key={config.DEFAULT_COLLECTION} value={config.DEFAULT_COLLECTION}>
                  🔒
                  {config.DEFAULT_COLLECTION}
                </option>
              )}
              
              {collections.map((collection) => (
                <option key={collection} value={collection}>
                  {collection}
                </option>
              ))}
            </select>
          </div>
        )}
       
        <Chat />
        <div className="w-full mb-auto flex items-center justify-center gap-2 pb-5 pl-10">
          <span
            style={{ minHeight: "7vh", height: isExpanded ? "auto" : "7vh" }}
            className="flex gap-2 mt-5 items-center justify-center bg-[#D9D9D9] rounded-lg shadow-md mx-auto w-[90%] lg:w-2/5 xl:w-1/2"
          >
            {dragging ? (
              <div className="text-center #F3F3F3 text-black py-2">
                Arrastrelo aquí
              </div>
            ) : (
              <>
                <textarea
                  ref={textareaRef}
                  placeholder={textConfig.CHAT_PLACEHOLDER || "Escribe un mensaje..."}
                  className={`h-full bg-[#D9D9D9] text-black bg-transparent px-3 ${
                    chatValue ? "py-2" : ""
                  } w-full border-none outline-none resize-none text-base ${
                    isWaitingForResponse ? "bg-black-100" : ""
                  }`}
                  value={chatValue}
                  onChange={autoResizeTextarea}
                  onKeyUp={handleKeyPress}
                  disabled={isWaitingForResponse}
                  style={{
                    minHeight: "20px",
                    background: "#D9D9D9",
                    marginTop: "0px",
                  }}
                />
              </>
            )}

            <RiSendPlane2Fill
              title="send message"
              className={
                chatValue.length <= 0
                  ? "text-gray-400 bg-[#D9D9D9] cursor-auto mx-3 text-xl"
                  : "text-white cursor-pointer mx-3 text-3xl bg-green-500 p-1 rounded shadow-md"
              }
              onClick={(e) => {
                e.preventDefault();
                handleSend();
              }}
            />
          </span>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ChatContainer;
